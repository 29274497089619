import { createApp } from "vue";
import App from "./App.vue";
import router from './router'

import 'swiper/swiper-bundle.css'

import 'bootstrap/dist/css/bootstrap.min.css'
import '../src/assets/css/font-awesome.min.css'
import '../src/assets/css/material-design-iconic-font.min.css'
import '../src/assets/css/ionicons.min.css'
import '../src/assets/css/flaticon.min.css'
import '../src/assets/css/animate.min.css'
import '../src/assets/scss/style.scss'

createApp(App).use(router).mount("#app");
